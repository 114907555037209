
// outsource dependencies
import { get } from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { HighlightOff, CheckCircleOutline, Close, DeleteOutline } from '@material-ui/icons';
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Tooltip,
    IconButton,
    Avatar
} from '@material-ui/core';

// local dependencies
import { LIST } from '../types';
import { history } from '../../../store';
import Page from '../../../components/page';
import { USERS } from '../../../constants/routes';
import Alert from '../../../components/alert-error';
import { Humanize } from '../../../components/filter';
import { SimpleSwitch } from '../../../components/md-switch';
import MdTablePagination from '../../../components/pagination';
import Preloader, { TYPE } from '../../../components/preloader';
import { SimpleAsyncSelect } from '../../../components/md-select';
import SearchFilter, { SimpleSearchField }  from '../../../components/search-filter';
import { OrganizationModel, UserRoleModel, BusinessUnitModel } from '../../../models';
import { Link, EditBtn, DelBtn, AddBtn, PrimaryBtn, WarningBtn } from '../../../components/md-button';
import {USERS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import {buildRolesString} from "../../../services/utils";

// configure
export const allowedSort = ['firstName', 'lastName', 'email', 'enabled'];

/**
 * Table
 *
 * @private
 */
const ConnectedTable = connect(
    state => ({
        list: state.users.list.list,
        page: state.users.list.page,
        size: state.users.list.size,
        totalPages: state.users.list.totalPages,
        disabled: state.users.list.expectAnswer,
    }),
    dispatch => ({
        init: () => dispatch({type: LIST.INITIALIZE}),
        changePage: page => dispatch({type: LIST.UPDATE_LIST, page}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null}),
        changeSize: size => dispatch({type: LIST.UPDATE_LIST, size, page: 0}),
        deleteItem: id => toastr.confirm(<ConfirmDeleting />, { onOk: () => dispatch({type: LIST.DELETE_ITEM, id}) }),
    })
)(({ list, disabled, page, size, totalPages, changePage, changeSize, deleteItem }) => (<Paper>
    <div style={{overflowX: 'auto'}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell style={{width: 70, maxWidth: 70}}></TableCell>
                    <SortableCell name="firstName" style={{whiteSpace: 'nowrap', width: '15%'}} />
                    <SortableCell name="lastName" style={{whiteSpace: 'nowrap', width: '15%'}} />
                    <SortableCell name="email" style={{whiteSpace: 'nowrap', width: '25%'}} />
                    <SortableCell name="organization" style={{whiteSpace: 'nowrap', width: '20%'}} />
                    <SortableCell name="vendors" style={{whiteSpace: 'nowrap', width: '20%'}} />
                    <TableCell className="th" style={{whiteSpace: 'nowrap', width: '25%'}}><strong>Roles</strong></TableCell>
                    <SortableCell name="enabled" style={{width: 80, maxWidth: 80}} />
                    <TableCell align="right" style={{minWidth: 200, width: 200}} />
                </TableRow>
            </TableHead>
            <TableBody>
                {list.map((item, index) => (<TableRow key={index}>
                    <TableCell style={{width: 70, maxWidth: 70}}>
                        {item && item.profilePicture && item.profilePicture.url ? (
                            <Avatar variant="rounded" src={item.profilePicture.url} alt={item.firstName} className="avatar" />
                        ) : ""}
                    </TableCell>
                    <TableCell>
                        {item.deleted && <DeleteOutline color="error" className="align-middle" />}&nbsp;{item.firstName}
                    </TableCell>
                    <TableCell> {item.lastName} </TableCell>
                    <TableCell> {item.email} </TableCell>
                    <TableCell> {get(item, 'organization.name')} </TableCell>
                    <TableCell> {buildRolesString(item.vendors)} </TableCell>
                    <TableCell> {buildRolesString(item.roles)} </TableCell>
                    <TableCell>
                        { item.enabled ? (<CheckCircleOutline color="primary" />) : (<HighlightOff color="error" />) }
                    </TableCell>
                    <TableCell align="right">
                        <Link
                            Btn={EditBtn}
                            disabled={disabled}
                            to={USERS.LINK_EDIT({id: item.id, query: {back: history.location.pathname + history.location.search} })}
                                />
                        &nbsp;&nbsp;&nbsp;
                        <DelBtn disabled={disabled} onClick={() => deleteItem(item.id)}/>
                    </TableCell>
                </TableRow>))}
            </TableBody>
        </Table>
    </div>
    <MdTablePagination
        page={page}
        size={size}
        disabled={disabled}
        totalPages={totalPages}
        changeSize={changeSize}
        changePage={changePage}
            />
</Paper>));

/**
 * Wrapper for sorting TH
 *
 * @private
 */
const SortableCell = connect(
    state => ({
        disabled: state.users.list.expectAnswer,
        sortF: state.users.list.sortF,
        sortD: state.users.list.sortD,
    }),
    dispatch => ({ changeSort: field => dispatch({type: LIST.CHANGE_SORT, field}) })
)(({disabled, sortF, sortD, name, changeSort, ...attr})=>(
    <TableCell className="th" {...attr}>
        <Tooltip
            enterDelay={300}
            placement="top-start"
            title={(<span>Sort by "<Humanize tag="strong" text={name} />"</span>)}
                >
            <span>
                <TableSortLabel
                    disabled={disabled}
                    active={sortF===name}
                    direction={sortD?'asc':'desc'}
                    onClick={()=>changeSort(name)}
                        >
                    <Humanize tag="strong" text={name} />
                </TableSortLabel>
            </span>
        </Tooltip>
    </TableCell>
));

/**
 * prepared confirmation for user deleting
 *
 * @public
 */
const ConfirmDeleting = () => (<div>
    <h3> Confirm deleting </h3>
    <p> Are you sure you want to mark the current organization as deleted? </p>
</div>);

/**
 * filters panel
 *
 * @public
 */
const FiltersPanelConnected = connect(
    state => ({
        disabled: state.users.list.expectAnswer,
        showAdvanced: state.users.list.showAdvanced,
    }),
    dispatch => ({
        update: () => {
            dispatch({type: LIST.UPDATE_LIST, page: 0})},
        closeAdvanced: () => dispatch({type: LIST.CANCEL_ADVANCED_SEARCH}),
        openAdvanced: () => {
            dispatch({type: LIST.META, showAdvanced: true});
            dispatch({type: LIST.UPDATE_LIST, filter: ''});
        },
        applyFilter: filter => {
            dispatch({type: LIST.UPDATE_LIST, filter, page: 0})
        }
    })
)( ({showAdvanced, update, openAdvanced, closeAdvanced, disabled}) => (<div>
    { showAdvanced ? (
        <Row className="offset-bottom-4">
            <Col xs={12}>
                <Card style={{overflow: 'visible'}}>
                    <CardHeader
                        title="Advanced search"
                        action={
                            <Tooltip title="Cancel">
                                <IconButton aria-label="Cancel advanced search" onClick={closeAdvanced}>
                                    <Close fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        }/>
                    <CardContent> <SearchForm /> </CardContent>
                    <CardActions style={{justifyContent: 'flex-end'}}>
                        <PrimaryBtn disabled={disabled} onClick={update} tooltip="Apply"> Apply </PrimaryBtn>
                        &nbsp;&nbsp;
                        <WarningBtn disabled={disabled} onClick={closeAdvanced} tooltip="Cancel"> Cancel </WarningBtn>
                    </CardActions>
                </Card>
            </Col>
        </Row>
    ) : (
        <Row>
            <Col xs={12} sm={6} lg={3} className="offset-bottom-2">
                <SearchFilterConnected />
            </Col>
            <Col xs={12} sm={6}>
                <PrimaryBtn onClick={openAdvanced} tooltip="Advanced search"> Advanced search </PrimaryBtn>
            </Col>
        </Row>
    )}
</div>));

/**
 * search form
 *
 * @public
 */
const SearchForm = connect(
    state => ({ ...state.users.list }),
    dispatch => ({ changeFilterValue: data => dispatch({type: LIST.META, ...data}) })
)( ({ expectAnswer, firstName, lastName, email, role, changeFilterValue, isDeleted }) => (<div>
    <Row>
        <Col xs={12} md={4} className="offset-bottom-2">
            <SimpleSearchField
                value={firstName}
                disabled={expectAnswer}
                label={(<strong> First Name </strong>)}
                clear={() => changeFilterValue({firstName: ''})}
                onChange={e => changeFilterValue({firstName: e.target.value})}
                    />
        </Col>
        <Col xs={12} md={4} className="offset-bottom-2">
            <SimpleSearchField
                value={lastName}
                disabled={expectAnswer}
                label={(<strong> Last Name </strong>)}
                clear={() => changeFilterValue({lastName: ''})}
                onChange={e => changeFilterValue({lastName: e.target.value})}
                    />
        </Col>
        <Col xs={12} md={4} className="offset-bottom-2">
            <SimpleSearchField
                value={email}
                disabled={expectAnswer}
                label={(<strong> Email </strong>)}
                clear={() => changeFilterValue({email: ''})}
                onChange={e => changeFilterValue({email: e.target.value})}
                    />
        </Col>
    </Row>
    <Row>
        <Col xs={12} md={6} lg={8} className="offset-bottom-2">
            <SimpleAsyncSelect
                isClearable={true}
                value={role}
                placeholder="Role"
                disabled={expectAnswer}
                label={(<strong> Role </strong>)}
                onChange={role => changeFilterValue({role})}
                loadOptions={(name, done) => {
                    UserRoleModel.getListByName(name, 6).then(done).catch(()=> done([]));
                }}/>
        </Col>
        <Col xs={12} md={6} lg={4} className="text-center top-indent-1 offset-bottom-1">
            <SimpleSwitch
                fullWidth={false}
                value={isDeleted}
                disabled={expectAnswer}
                label={(<strong> Show deleted </strong>)}
                onChange={isDeleted => changeFilterValue({isDeleted})}
                    />
        </Col>
    </Row>
    <Row><Col xs={12}> <OrganizationFormConnected /> </Col></Row>
</div>));

/**
 *
 * @private
 */
const SearchFilterConnected = connect(
    state => ({
        filter: state.users.list.filter,
        disabled: state.users.list.expectAnswer,
    }),
    dispatch => ({
        changeFilterValue: filter => dispatch({type: LIST.META, filter}),
        applyFilter: filter => dispatch({type: LIST.UPDATE_LIST, filter, page: 0}),
    })
)(({disabled, filter, changeFilterValue, applyFilter}) => (
    <SearchFilter
        value={filter}
        disabled={disabled}
        apply={applyFilter}
        clear={() => applyFilter('')}
        onInputChange={changeFilterValue}
        placeholder="Search by name or email"
            />
));

class OrganizationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            businessUnits: [],
        }
        // NOTE fix react warnings
        let oss = this.setState.bind(this);
        this.setState = ( ...args ) => !this.isUnmauonted&&oss(...args);
    }

    componentDidMount() {
        let { organization } = this.props;
        this.getBusinessUnits(organization);
    }

    changeOrganization ( organization ) {
        let { changeFilterValue } = this.props;
        changeFilterValue({organization});
        changeFilterValue({businessUnit: null});
        this.getBusinessUnits(organization)
    }

    getBusinessUnits (organization) {
        organization && (
            BusinessUnitModel
                .getPage({size: 6, filter: { organizationId: get(organization, 'id', null) }})
                .then(({items})=>{this.setState({businessUnits: items})})
                .catch(({message})=> this.props.showError(message))
        )
    }

    // NOTE fix react warnings
    componentWillUnmount() { this.isUnmauonted = true; }

    render() {
        let { organization, businessUnit, expectAnswer, changeFilterValue } = this.props;
        let { businessUnits } = this.state;
        return (<Row>
            <Col xs={12} md={6} className="offset-bottom-2">
                <SimpleAsyncSelect
                    isClearable={true}
                    value={organization}
                    disabled={expectAnswer}
                    placeholder="Organization"
                    label={(<strong> Organization </strong>)}
                    onChange={organization => this.changeOrganization(organization)}
                    loadOptions={(name, done) => {
                        OrganizationModel.getListByName(name, 6).then(done).catch(()=> done([]));
                    }}/>
            </Col>
            <Col xs={12} md={6} className="offset-bottom-2">
                <SimpleAsyncSelect
                    isClearable={true}
                    value={businessUnit}
                    placeholder="Business Unit"
                    defaultOptions={businessUnits}
                    disabled={!organization || expectAnswer}
                    label={(<strong> Business Unit </strong>)}
                    getOptionLabel={item => item.fullLabel}
                    onChange={businessUnit => changeFilterValue({businessUnit})}
                    loadOptions={(name, done) => {
                        BusinessUnitModel
                            .getPage({size: 6, filter: { name, organizationId: get(organization, 'id', null) }})
                            .then(({items})=>done(items)).catch(()=> done([]));
                    }}/>
            </Col>
        </Row>)
    }
}

const OrganizationFormConnected = connect(
    state => ({ ...state.users.list }),
    dispatch => ({
        showError: errorMessage => dispatch({type: LIST.META, errorMessage}),
        changeFilterValue: data => dispatch({type: LIST.META, ...data})
    })
)(OrganizationForm);



/**
 * Table wrapper
 *
 * @public
 */
export default connect(
    state => ({ ...state.users.list }),
    dispatch => ({
        init: () => dispatch({type: LIST.INITIALIZE}),
        clearError: () => dispatch({type: LIST.META, errorMessage: null}),
    })
)(({ list, init, initialized, expectAnswer, errorMessage, clearError }) => (
    <Page init={init} initialized={initialized}>
        <Row>
            <Col xs={8}> <h2 className="text-uppercase offset-bottom-4">
                User List &nbsp; <Preloader type={TYPE.SPINNER} black style={{width: '25px'}} expectAnswer={expectAnswer} />
            </h2>
                <Breadcrumbs breadCrumbsMap={ USERS_MAP }  />
            </Col>
            <Col xs={4} className="text-right top-indent-4">
                <Link Btn={AddBtn} to={USERS.LINK_EDIT()} disabled={expectAnswer} tooltip="Create user" />
            </Col>
        </Row>
        <Row> <Col xs={12} md={8} mdOffset={2}> <Alert active message={errorMessage} onChange={clearError} /> </Col> </Row>
        <Row> <Col xs={12}> <FiltersPanelConnected /> </Col> </Row>
        <Row> <Col xs={12}>
            {list.length ? (<ConnectedTable />) : (<h3 className="text-uppercase text-center text-highlighted"> There is no users </h3>)}
        </Col> </Row>
    </Page>
));
